import Service from './Service'

export const SERVICES = {
  getPackages: {
    type: 'get',
    extraParams: {
      for_web: true,
    },
  },
  buyPackage: {
    type: 'post',
    urlParam: '/via_card',
  },
}

/**
 * Instance of Packages Service.
 *
 * @class
 * @usage `import PackagesService from '~/network/PackagesService'`
 */
export default class PackagesService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'packages'
  }
}
