import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Input component here>
 *
 * @component
 * @usage import Input from '~/components/Input'
 * @example
 * <Add an example of how Input is used here>
 */
const Input = ({ label, onChange, ...extra }) => {
  return (
    <div css={style.inputContainer}>
      {label && <label css={style.label}>{label}</label>}
      <input
        css={style.input}
        onChange={ev => onChange(ev.target.value)}
        {...extra}
      />
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
}

Input.defaultProps = {
  label: '',
  onChange: () => {},
}

export default Input
