import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from '~/styles/colors'
import { transition } from '~/styles/constants'

export const inputContainer = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const input = css`
  outline: none;
  border: none;
  font-size: 1.25rem;
  background: ${transparentize(0.4, colors.white)};
  border-radius: 0.25rem;
  padding: 0.25rem;
  ${transition};

  &:focus {
    background: ${transparentize(0.2, colors.white)};
  }
`

export const label = css`
  margin-bottom: 0.5rem;
`