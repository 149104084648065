import Store, { setObservables } from './Store'
import CategoriesService from '~/network/CategoriesService.js'
import { observable, toJS } from 'mobx'
import { isEmpty } from 'lodash'

const OBSERVABLES = {
  _categories: { type: observable, defaultValue: [] },
}

class CategoriesStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new CategoriesService(this.rootStore)
  }

  async getCategories() {
    if (!isEmpty(this.categories)) {
      return this.categories
    }

    const categoriesCall = await this.storeApiResponse({
      apiName: 'getCategories',
      observableName: '_categories',
    })

    return categoriesCall
  }

  setCategories(value) {
    this._categories = value
  }

  get categories() {
    return toJS(this._categories)
  }
}

export default CategoriesStore
