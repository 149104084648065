import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { transparentize, darken } from 'polished'

const SCROLLBAR_DIMENSIONS = '0.5rem'
const SCROLLBAR_COLOR = colors.white

const scrollbar = css`
  &::-webkit-scrollbar {
    width: ${SCROLLBAR_DIMENSIONS};
    height: ${SCROLLBAR_DIMENSIONS};
  }

  &::-webkit-scrollbar-track {
    border-radius: 1rem;
    background: ${transparentize(0.75, SCROLLBAR_COLOR)};
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: ${SCROLLBAR_COLOR};
    transition: all 0.2s;

    &:hover {
      cursor: grab;
      background: ${transparentize(0.05, SCROLLBAR_COLOR)};
    }

    &:active {
      cursor: grabbing;
      background: ${darken(0.05, SCROLLBAR_COLOR)};
    }
  }
`

export default scrollbar
