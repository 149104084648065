import React, { useEffect } from 'react'
import App from 'next/app'
import NextHead from 'next/head'

import * as Sentry from '@sentry/browser'
import { appWithTranslation } from 'next-i18next'
import { getRootStore } from '~/stores/RootStore'
import { Provider } from 'mobx-react'
import { CookieHelper } from '@sowlutions-tech/next/common/helpers'
import stringify from 'json-stringify-safe'
import { IsDevelopment, IsServer } from '@sowlutions-tech/next/common/utils'
import { router } from '~/lib/router'
import { CONFIG, SENTRY_DSN } from '~/global.config'
import { SEO } from '~/components/Meta'
import StandardLayout from '~/layouts/Standard'

import './_app.scss'

if (!IsDevelopment && CONFIG.withSentry) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: IsDevelopment ? 'development' : 'production',
  })

  process.on('unhandledRejection', err => Sentry.captureException(err))
  process.on('uncaughtException', err => Sentry.captureException(err))
}

const NextJSMobxApp = ({ Component, pageProps, rootStoreString, currentRoute }) => {
  // ? Pull rootStoreString from __NEXT_DATA__, deserialize, and pass to client-side RootStore
  let serializer
  stringify.getSerialize(serializer)
  const rootStore = getRootStore(JSON.parse(rootStoreString, serializer))
  const currentRouteParsed = IsServer ? currentRoute : router.getCurrentRoute()

  useEffect(() => {
    if (!IsServer && IsDevelopment) {
      global.window.rootStore = rootStore
      // global.window.currentRoute = router.getCurrentRoute()
    }
  }, [])

  useEffect(() => {
    const { currentUser } = rootStore.authStore
    const email = currentUser ? currentUser.email : 'unknown'

    if (!IsServer && email) {
      Sentry.configureScope(scope => {
        scope.setUser({ email })
      })
    }

    // if (!IsServer && IsDevelopment) {
    //   global.window.currentRoute = router.getCurrentRoute()
    // }
  }, [rootStore.authStore])

  return (
    <>
      <NextHead>
        <meta charSet='UTF-8' />
        <title>{SEO.title}</title>
        <meta name='description' content={SEO.description} />
        <link rel='stylesheet' href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link href='https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap' rel='stylesheet' />

        <link rel='apple-touch-icon' sizes='57x57' href='/static/favicon/apple-icon-57x57.png' />
        <link rel='apple-touch-icon' sizes='60x60' href='/static/favicon/apple-icon-60x60.png' />
        <link rel='apple-touch-icon' sizes='72x72' href='/static/favicon/apple-icon-72x72.png' />
        <link rel='apple-touch-icon' sizes='76x76' href='/static/favicon/apple-icon-76x76.png' />
        <link rel='apple-touch-icon' sizes='114x114' href='/static/favicon/apple-icon-114x114.png' />
        <link rel='apple-touch-icon' sizes='120x120' href='/static/favicon/apple-icon-120x120.png' />
        <link rel='apple-touch-icon' sizes='144x144' href='/static/favicon/apple-icon-144x144.png' />
        <link rel='apple-touch-icon' sizes='152x152' href='/static/favicon/apple-icon-152x152.png' />
        <link rel='apple-touch-icon' sizes='180x180' href='/static/favicon/apple-icon-180x180.png' />
        <link rel='icon' type='image/png' sizes='192x192' href='/static/favicon/android-icon-192x192.png' />
        <link rel='icon' type='image/png' sizes='32x32' href='/static/favicon/favicon-32x32.png' />
        <link rel='icon' type='image/png' sizes='96x96' href='/static/favicon/favicon-96x96.png' />
        <link rel='icon' type='image/png' sizes='16x16' href='/static/favicon/favicon-16x16.png' />
        <link rel='manifest' href='/static/favicon/manifest.json' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='msapplication-TileImage' content='/static/favicon/ms-icon-144x144.png' />
        <meta name='theme-color' content='#ffffff' />
      </NextHead>
      <Provider rootStore={rootStore}>
        <StandardLayout currentRoute={currentRouteParsed} rootStore={rootStore}>
          <Component
            {...pageProps}
            currentRoute={currentRouteParsed}
          />
        </StandardLayout>
      </Provider>
    </>
  )
}

NextJSMobxApp.getInitialProps = async (appContext) => {
  const cookieObject = appContext.ctx.req
    ? CookieHelper.getTokenData(appContext.ctx.req.headers.cookie) // Server side
    : CookieHelper.getTokenData(document.cookie)

  // ? On server-side, this runs once and creates new stores
  const rootStore = getRootStore({ authStore: cookieObject })

  // ? Make stores available to page's `getInitialProps`
  appContext.ctx.rootStore = rootStore

  // ? Call "super" to run page's `getInitialProps`
  let appProps

  if (App.getInitialProps) {
    appProps = await App.getInitialProps(appContext)
  }

  // ? Generate a serialized RootStore to pass below (in return)
  // ? __NEXT_DATA
  const rootStoreString = stringify(rootStore, null)

  return {
    ...appProps,
    currentRoute: router.getCurrentRoute(appContext.ctx),
    rootStoreString,
  }
}

const HOCWrapper = (app) => {
  let wrappedApp = app
  const { withTranslations } = CONFIG

  if (withTranslations) {
    wrappedApp = appWithTranslation(wrappedApp)
  }

  return wrappedApp
}

export default HOCWrapper(NextJSMobxApp)
