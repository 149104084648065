import React from 'react'

import Modal from '~/components/Modal'

import Logo from '~/public/static/logo.svg'
import ButtonStoreGoogle from '~/public/static/images/button-store-google.svg'
import ButtonStoreApple from '~/public/static/images/button-store-apple.svg'

import * as style from './styles'

const TEXTS = {
  enjoyFeatures: 'يمكنك الاستمتاع بجميع ميزات التطبيق.',
  downloadFromStore: 'قم بتنزيله من المتجر.',
}

/**
 * <Describe the ModalAppStore component here>
 *
 * @component
 * @usage import ModalAppStore from '~/components/ModalAppStore'
 * @example
 * <Add an example of how ModalAppStore is used here>
 */
const ModalAppStore = (props) => {
  return (
    <Modal
      {...props}
      header={
        <>
          <span>{TEXTS.enjoyFeatures}</span>
          <span>{TEXTS.downloadFromStore}</span>
        </>
      }
    >
      <div css={style.logoContainer}>
        <Logo css={style.logo} />
          Hazzi
      </div>
      <div css={style.buttonsDownload}>
        <ButtonStoreGoogle
          css={style.buttonDownload}
        />
        <ButtonStoreApple
          css={style.buttonDownload}
        />
      </div>
    </Modal>
  )
}

export default ModalAppStore
