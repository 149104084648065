import Service from './Service'

export const SERVICES = {
  getFortuneTeller: {
    type: 'get',
    urlParam: '/:id',
  },
}

/**
 * Instance of FortuneTellers Service.
 *
 * @class
 * @usage `import FortuneTellersService from '~/network/FortuneTellersService'`
 */
export default class FortuneTellersService extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'fortune_tellers'
  }
}
