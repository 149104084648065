import { css } from '@emotion/react'
import { HEADER_HEIGHT, FOOTER_HEIGHT, MAX_SCREEN_WIDTH } from '~/styles/constants'
import colors from '~/styles/colors'
import scrollbar from '~/styles/mixins/scrollbar'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const layout = css`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
  color: ${colors.white};
  background: url("/static/backgrounds/background-stars.png"), linear-gradient(0deg, ${colors.darkPurple} 0%, ${colors.lightPurple} 100%);

  a {
    color: inherit;
    text-decoration: none;
  }

`

export const main = css`
  display: flex;
  height: calc(100vh - ${HEADER_HEIGHT} - ${FOOTER_HEIGHT});
  width: 100%;
  max-width: ${MAX_SCREEN_WIDTH};
  margin: auto;

  ${mqMax.md} {
    width: unset;
    height: 100%;
    padding: 0.5rem;
  }
  
  * {
    ${scrollbar};
  }

  > section {
    width: 100%;
  }
`
