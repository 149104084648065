import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Modal component here>
 *
 * @component
 * @usage import Modal from '~/components/Modal'
 * @example
 * <Add an example of how Modal is used here>
 */
const Modal = ({ isOpen, onClose, header, children, ...extra }) => {
  const [overlayStyles, setOverlayStyles] = useState({ display: 'none' })
  const [modalStyles, setModalStyles] = useState({ opacity: 0, transform: 'scale(0.75)' })

  useEffect(() => {
    if (isOpen) {
      setOverlayStyles({ display: 'flex' })

      setTimeout(() => {
        setOverlayStyles({ display: 'flex', opacity: 1 })
        setModalStyles({ opacity: 1, transform: 'none' })
      }, 10)
    } else {
      setOverlayStyles({ display: 'flex', opacity: 0 })
      setModalStyles({ opacity: 0, transform: 'scale(0.75)' })

      setTimeout(() => {
        setOverlayStyles({ display: 'none' })
      }, 350)
    }
  }, [isOpen])

  return (
    <aside
      css={style.backdrop}
      style={overlayStyles}
      onClick={onClose}
    >
      <div
        css={style.modal}
        style={modalStyles}
        onClick={ev => ev.stopPropagation()}
        {...extra}
      >
        {
          header && (
            <div css={style.header}>
              {header}
            </div>
          )
        }
        {children}
      </div>
    </aside>
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.element,
}

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  header: null,
}

export default Modal
