import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Modal from '~/components/Modal'
import Input from '~/components/Input'
import Button from '~/components/Button'
import Message from '~/components/Message'
import * as style from './styles'

const TEXTS = {
  login: 'تسجيل الدخول',
  email: 'البريد الإلكتروني',
  token: 'رمز',
  loginButton: 'ادخل',
  incorrectCredentials: 'بريد أو كلمة مرورغير صحيحة',
}

const isValidEmail = (email) => {
  const expr = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return expr.test(email)
}

/**
 * <Describe the ModalLogin component here>
 *
 * @component
 * @usage import ModalLogin from '~/components/ModalLogin'
 * @example
 * <Add an example of how ModalLogin is used here>
 */
const ModalLogin = ({ onLogin, error, message, ...props }) => {
  const [hasRequestedToken, setHasRequestedToken] = useState(false)
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (message || error) {
      setLoading(false)
    }
  }, [message, error])

  useEffect(() => {
    if (message) {
      setHasRequestedToken(true)
    }
  }, [message])


  const submitButtonEnabled = hasRequestedToken && token != '' || !hasRequestedToken && isValidEmail(email)

  const handleSubmit = (params) => {
    onLogin(params)
    setLoading(true)
    setEmail('')
    setToken('')
  }

  return (
    <Modal
      {...props}
      header={<span>{TEXTS.login}</span>}
      css={style.modal}
    >
      <Message type='error'>{error}</Message>
      <Message type='success'>{message}</Message>
      <div>
        {
          !hasRequestedToken
            ? (
              <Input
                label={TEXTS.email}
                onChange={setEmail}
                css={style.validBorder(isValidEmail(email))}
                value={email}
              />
            )
            : (
              <Input
                label={TEXTS.token}
                onChange={setToken}
                css={style.validBorder(token != '')}
                value={token}
              />
            )
        }
      </div>
      <Button
        isOutlined
        color='white'
        css={style.submitButton(submitButtonEnabled)}
        isLoading={loading}
        onClick={() => handleSubmit({ email, token })}
      >
          {loading ? '...' : TEXTS.loginButton}
      </Button>
    </Modal>
  )
}

ModalLogin.propTypes = {
  onLogin: PropTypes.func,
  error: PropTypes.string,
  message: PropTypes.string,
}

ModalLogin.defaultProps = {
  onLogin: () => {},
  error: '',
  message: '',
}

export default ModalLogin
