import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { FOOTER_HEIGHT, MAX_SCREEN_WIDTH, transition } from '~/styles/constants'
import { transparentize } from 'polished'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const footer = css`
  background-color: ${transparentize(0.9, colors.white)};
  box-shadow: 0rem -0.25rem 1rem 0rem ${transparentize(0.9, colors.white)};

  ${mqMax.sm} {
    padding-bottom: 1rem;
  }
`

export const footerContainer = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${FOOTER_HEIGHT};
  max-width: ${MAX_SCREEN_WIDTH};
  font-size: 1.5rem;
  margin: auto;

  ${mqMax.xl} {
    padding: 0 5%;
  }

  ${mqMax.sm} {
    flex-direction: column;
  }
`

export const brand = css`
  font-size: 2rem;
  font-weight: bold;
  width: 10rem;

  ${mqMax.md} {
    width: auto;
  }
`

export const callToActions = css`
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5rem;
  width: 50%;

  ${mqMax.sm} {
    width: 90%;
    font-size: 1.25rem;
  }
`

export const iconsContainer = css`
  display: flex;
  justify-content: space-between;
  width: 10rem;

  ${mqMax.md} {
    width: auto;
  }
  
  ${mqMax.sm} {
    width: 50%;
  }
`

export const icon = color => css`
  font-size: 1.75rem;
  width: 1.75rem;
  height: 1.75rem;
  border: 0.15rem solid ${transparentize(0.6, colors.white)};
  border-radius: 100%;
  padding: 0.6rem;
  ${transition};
  cursor: pointer;

  ${mqMax.md} {
    margin-right: 1rem;
  }

  ${mqMax.sm} {
    margin: 0
  }

  &:hover {
    color: ${colors[color]}
  }
`