import { css } from '@emotion/react'
import { boxShadow, dropShadow, transition } from '~/styles/constants'
import { mqMax } from '~/styles/mixins/mediaQueries'

const BORDER_RADIUS = '1rem'

export const logoContainer = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const logo = css`
  border-radius: ${BORDER_RADIUS};
  padding: 1rem;
  margin-bottom: 1rem;
  ${boxShadow};
`

export const buttonsDownload = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const buttonDownload = css`
  width: 40%;
  cursor: pointer;
  ${transition}

  ${mqMax.lg} {
    width: 30%;
  }

  ${mqMax.md} {
    width: 100%;
  }

  &:hover {
    ${dropShadow};
  }
`
