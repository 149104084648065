import { css } from '@emotion/react'

export const validBorder = (isValid) => css`
  ${!isValid && `
    border: 2px solid red;
  `};
`
export const submitButton = (isValid) => css`
  ${!isValid && `
    opacity: 0.2;
  `};
`
export const modal = css`
  input {
    text-align: center;
  }
`
