import Store, { setObservables } from './Store'
import PackagesService from '~/network/PackagesService.js'
import { observable, toJS } from 'mobx'
import { isEmpty } from 'lodash'

const OBSERVABLES = {
  _packages: { type: observable, defaultValue: [] },
}

class PackagesStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new PackagesService(this.rootStore)
  }

  async getPackages() {
    if (!isEmpty(this.packages)) {
      return this.packages
    }

    const packagesCall = await this.storeApiResponse({
      apiName: 'getPackages',
      observableName: '_packages',
    })

    return packagesCall
  }

  setPackages(value) {
    this._packages = value
  }

  get packages() {
    return toJS(this._packages)
  }
}

export default PackagesStore
