import Store, { setObservables } from './Store'
import { action, observable, computed, toJS } from 'mobx'
import { isEmpty, isNil } from 'lodash'
import { CookieHelper } from '@sowlutions-tech/next/common/helpers'
import Authentication from '~/network/Authentication'

const OBSERVABLES = {
  _authToken: { type: observable, defaultValue: '' },
  _currentUser: { type: observable, defaultValue: '' },
  setCurrentUser: { type: action },
  logout: { type: action },
  isLoggedIn: { type: computed },
  hasUserInfo: { type: computed },
}

class AuthStore extends Store {
  constructor(initialData) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)

    if (initialData) {
      let dataSource = initialData

      if (initialData.cookie) {
        dataSource = initialData.cookie
      }

      this._authToken = dataSource._authToken
      this._currentUser = dataSource
    }

    this.reloadUser()
  }

  service() {
    return new Authentication({ authStore: this })
  }

  setCurrentUser(user) {
    const { auth_token, authToken, ...userInfo } = user
    this._currentUser = userInfo
    const authTokenParsed = auth_token || authToken
    this._authToken = authTokenParsed

    CookieHelper.saveTokenData({
      tokenData: {
        _authToken: authTokenParsed,
        _currentUser: { id: userInfo.id },
      },
    })
  }

  async login(params) {
    const resp = await this.api().login(params)

    if (resp.success) {
      await this.setCurrentUser(resp.data.user)
    }

    return resp
  }

  logout() {
    CookieHelper.clearTokenData()
    this._currentUser = null
    this._authToken = null
  }

  async reloadUser() {
    if (this._authToken) {
      const resp = await this.api().getUserData()
      if (resp.id) {
        if (!resp.auth_token) {
          resp.auth_token = this._authToken
        }
        this.setCurrentUser(resp)
      }
    }
  }

  setAuthToken(value) {
    this._authToken = value
  }

  get isLoggedIn() {
    return !isEmpty(this._authToken)
  }

  get authToken() {
    return toJS(this._authToken)
  }

  get hasUserInfo() {
    return !isEmpty(this.currentUser) && !isNil(this.currentUser.id) // ? `id` can be replaced with any key should only exist when the user info has been queried
  }

  get currentUser() {
    return toJS(this._currentUser)
  }
}

export default AuthStore
