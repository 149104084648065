import Store, { setObservables } from './Store'
import { observable, toJS } from 'mobx'

const OBSERVABLES = {
  _isLoading: { type: observable, defaultValue: false },
  _isAppModalOpen: { type: observable, defaultValue: false },
  _isLoginModalOpen: { type: observable, defaultValue: false },
  _isPurchaseModalOpen: { type: observable, defaultValue: false },
}

class CategoriesStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  setIsLoading(value) {
    this._isLoading = value
  }

  setIsAppModalOpen(value) {
    this._isAppModalOpen = value
  }

  setIsLoginModalOpen(value) {
    this._isLoginModalOpen = value
  }

  setIsPurchaseModalOpen(value) {
    this._isPurchaseModalOpen = value
  }

  get isLoading() {
    return toJS(this._isLoading)
  }

  get isAppModalOpen() {
    return toJS(this._isAppModalOpen)
  }

  get isLoginModalOpen() {
    return toJS(this._isLoginModalOpen)
  }

  get isPurchaseModalOpen() {
    return toJS(this._isPurchaseModalOpen)
  }
}

export default CategoriesStore
