import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { HEADER_HEIGHT, MAX_SCREEN_WIDTH, dropShadow, transition } from '~/styles/constants'
import { transparentize } from 'polished'
import { mqMax } from '~/styles/mixins/mediaQueries'
import animationSpin3D from '~/styles/mixins/animations/spin3d'

export const header = css`
  background-color: ${transparentize(0.9, colors.white)};
  box-shadow: 0rem 0.25rem 1rem 0rem ${transparentize(0.9, colors.white)};
`

export const headerContainer = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${HEADER_HEIGHT};
  max-width: ${MAX_SCREEN_WIDTH};
  margin: auto;

  ${mqMax.xl} {
    padding: 0 5%;
  }
  
  ${mqMax.sm} {
    flex-direction: column;
    padding: 1rem 0;
  }
`

export const slogan = css`
  display: flex;
  align-items: center;
  font-size: 2rem;

  > b {
    margin-left: 0.4rem;
  }
`

export const iconLogo = ({ isOnHomepage, isLoading }) => css`
  margin-left: 2rem;
  ${isLoading && animationSpin3D};
  ${transition};

  ${mqMax.sm} {
    margin: 0;
  }

  &:hover {
    ${!isOnHomepage && dropShadow}
  }
`

export const sloganCompany = css`
  ${mqMax.md} {
    display: none;
  }
`

export const sloganText = css`
  ${mqMax.md} {
    display: none;
  }
`

export const userActions = css`
  display: flex;
  align-items: center;

  ${mqMax.sm} {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
`

export const balancesContainer = css`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;

  ${mqMax.sm} {
    position: absolute;
    top: 1rem;
    right: 1rem;
    margin: 0;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > b {
      margin-right: 1rem;
    }

    > svg {
      height: 1.5rem;
    }
  }
`

export const store = css`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  cursor: pointer;
  ${transition};

  &:hover {
    ${dropShadow};
  }
`

export const iconStore = css`
  margin-right: 1rem;
  margin-left: 2rem;

  ${mqMax.sm} {
    display: none;
  }
`

export const userName = css`
  font-size: 2rem;
  font-weight: bold;
  color: ${colors.white};
`
