import { RootStore } from '@sowlutions-tech/next/common/stores'

import AuthStore from './AuthStore'
import CategoriesStore from './CategoriesStore'
import PackagesStore from './PackagesStore'
import FortuneTellersStore from './FortuneTellersStore'
import StatesStore from './StatesStore'
import CardsStore from './CardsStore'
// * [reserved] [import_generated_store]

const STORES = {
  authStore: AuthStore,
  categoriesStore: CategoriesStore,
  packagesStore: PackagesStore,
  fortuneTellersStore: FortuneTellersStore,
  statesStore: StatesStore,
  cardsStore: CardsStore,
  // * [reserved] [declare_generated_store]
}

const getRootStore = (initialData) => RootStore.getRootStore(initialData, STORES)

export {
  getRootStore,
}
