import Store, { setObservables } from './Store'
import FortuneTellersService from '~/network/FortuneTellersService.js'

const OBSERVABLES = {
}

class FortuneTellersStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new FortuneTellersService(this.rootStore)
  }
}

export default FortuneTellersStore
