import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'

import Header from '~/components/Header'
import Footer from '~/components/Footer'

import * as style from './styles.js'

/**
 * StandardLayout
 *
 * @component
 * @usage `import StandardLayout from '~/layouts/Standard'`
 * @example
 * // Will automatically wrap all children in _app.js
 */

const StandardLayout = ({ rootStore, children, currentRoute }) => {
  const router = useRouter()

  useEffect(() => {
    const turnOnLoading = () => {
      rootStore.statesStore.setIsLoading(true)
    }

    const turnOffLoading = () => {
      rootStore.statesStore.setIsLoading(false)
    }

    router.events.on('routeChangeStart', turnOnLoading)
    router.events.on('routeChangeComplete', turnOffLoading)

    return () => {
      router.events.off('routeChangeStart', turnOnLoading)
      router.events.off('routeChangeComplete', turnOffLoading)
    }
  }, [])

  return (
    <section css={style.layout}>
      <Header
        currentRoute={currentRoute}
        rootStore={rootStore}
      />
      <main css={style.main}>
        {children}
      </main>
      <Footer />
    </section>
  )
}

export default observer(StandardLayout)
