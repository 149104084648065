import React from 'react'

import Icon from '~/components/Icon'

import * as style from './styles'

const TEXTS = {
  terms: 'الإعدادات',
  join: 'شارك Hazzi',
}

/**
 * Footer
 *
 * @component
 * @usage `import Footer from '~/components/Footer'`
 */
const Footer = () => {
  return (
    <footer css={style.footer}>
      <div css={style.footerContainer}>
        <div css={style.brand}>
          Hazzi
        </div>
        <a href='http://terms.hazziapp.net/' target='_blank' rel='noreferrer'>
          {TEXTS.terms}
        </a>
        {/* <div css={style.callToActions}>
          <span>{TEXTS.terms}</span>
          <span>{TEXTS.join}</span>
        </div>
        <div css={style.iconsContainer}>
          <span css={style.icon('facebook')}>
            <Icon icon='facebook' />
          </span>
          <span css={style.icon('twitter')}>
            <Icon icon='twitter' />
          </span>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
