import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { transparentize } from 'polished'
import { zIndexes, boxShadow } from '~/styles/constants'
import { mqMax } from '~/styles/mixins/mediaQueries'

const BORDER_RADIUS = '1rem'

export const backdrop = css`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 0;
  top: 0;
  background: ${transparentize(0.75, colors.black)};
  width: 100%;
  height: 100%;
  backdrop-filter: blur(1.5rem);
  z-index: ${zIndexes.overlay};
  transition: 0.3s ease-in-out;
`

export const modal = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url("/static/backgrounds/background-stars.png"), linear-gradient(0deg, ${colors.darkPurple} 0%, ${colors.lightPurple} 100%);
  min-height: 25rem;
  height: 50%;
  width: 50%;
  max-width: 45rem;
  max-height: 30rem;
  padding: 2rem 4rem;
  border-radius: ${BORDER_RADIUS};
  font-size: 2rem;
  ${boxShadow};
  transition: 0.3s ease-in-out;

  ${mqMax.lg} {
    font-size: 1.5rem;
    height: 40%;
    width: 60%;
  }

  ${mqMax.md} {
    font-size: 1.5rem;
    height: 40%;
    width: 60%;
  }

  ${mqMax.sm} {
    padding: 1rem;
    font-size: 1.3rem;
    width: 80%;
  }
`

export const header = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`