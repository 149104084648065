import React, { useState } from 'react'
import { observer } from 'mobx-react'

import { router, Link } from '~/lib/router'
import Button from '~/components/Button'
import ModalAppStore from '~/components/ModalAppStore'
import ModalLogin from '~/components/ModalLogin'

import IconCoin from '~/public/static/images/icon-coin.svg'
import IconCoinsBag from '~/public/static/images/icon-coins-bag.svg'
import Logo from '~/public/static/logo.svg'

import * as style from './styles'

const TEXTS = {
  company: 'حظي',
  slogan: '- تبصير وقراءة فنجان',
  store: 'المتجر',
  login: 'تسجيل الدخول',
  wrongToken: 'الرمز خطأ',
}

/**
 * Header / Navbar
 *
 * @component
 * @usage `import Header from '~/components/Header'`
 */
const Header = ({ rootStore, currentRoute }) => {
  const { statesStore, authStore } = rootStore
  const { isLoading, isAppModalOpen, isLoginModalOpen } = statesStore
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const isOnHomepage = currentRoute.key === 'home'

  const { isLoggedIn, currentUser } = authStore

  const handleLogin = async ({ email, token, onStartCallback, onDoneCallback }) => {
    setError('')

    if (email && !token) {
      onStartCallback && onStartCallback()
      const loginPost = await authStore.login({ email })
      onDoneCallback && onDoneCallback()

      if (loginPost.error) {
        setError(loginPost.error)
      } else if (loginPost.message) {
        setMessage(loginPost.message)
      }
    } else if (token) {
      rootStore.authStore.setAuthToken(token)
      onStartCallback && onStartCallback()
      const userGet = await authStore.api().getUserData()
      onDoneCallback && onDoneCallback()

      if (userGet.id) {
        authStore.setCurrentUser(userGet)
        statesStore.setIsLoginModalOpen(false)
      } else {
        setMessage('')
        setError(TEXTS.wrongToken)
      }
    }
  }

  return (
    <>
      <ModalAppStore
        isOpen={isAppModalOpen}
        onClose={() => statesStore.setIsAppModalOpen(false)}
      />
      <ModalLogin
        isOpen={isLoginModalOpen}
        onClose={() => statesStore.setIsLoginModalOpen(false)}
        onLogin={handleLogin}
        error={error}
        message={message}
      />
      <header css={style.header}>
        <div css={style.headerContainer}>
          <div css={style.slogan}>
            <Link href={router.routes.home}>
              <Logo css={style.iconLogo({ isOnHomepage, isLoading })} />
            </Link>
            <b css={style.sloganCompany}>{TEXTS.company}</b>
            <span css={style.sloganText}>{TEXTS.slogan}</span>
          </div>
          <div css={style.userActions}>
            <div css={style.balancesContainer}>
              {isLoggedIn && <span>{currentUser.balance} <IconCoin /></span>}
            </div>
            <Link
              href={router.routes.store}
              css={style.store}
            >
              {TEXTS.store}
              <IconCoinsBag css={style.iconStore} />
            </Link>
            {
              isLoggedIn
                ? (
                  <span css={style.userName}>
                    {currentUser.first_name}
                  </span>
                )
                : (
                  <Button onClick={() => statesStore.setIsLoginModalOpen(true)}>
                    {TEXTS.login}
                  </Button>
                )
            }
          </div>
        </div>
      </header>
    </>
  )
}

export default observer(Header)
